import React from 'react';

import loadable from '@loadable/component';

import Layout from '../components/layout';

import Seo from '../components/seo';

import HeroSection from '../components/HeroSection/HeroSection';

const Products = loadable(() => import('../components/Products/Products'), {
  ssr: true,
  fallback: <span className="w-12 h-12 rounded-full animate-spin border-y border-solid border-yellow-500 border-t-transparent" />,
});
const Partners = loadable(() => import('../components/Partners/Partners'), {
  ssr: true,
});
const BlogSection = loadable(() => import('../components/Blog/BlogSection'), {
  ssr: true,
});
const Testimonials = loadable(() => import('../components/Testimonials/Testimonials'), {
  ssr: true,
});
const ServicesSection = loadable(() => import('../components/ServicesSection/ServicesSection'), {
  ssr: true,
});

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <HeroSection />
    <Partners
      prefix="ssr: true"
      data-sal="slide-up"
      data-sal-duration="2000"
      data-sal-delay="300"
      data-sal-easing="ease"
    />
    <ServicesSection
      prefix="ssr: true"
      data-sal="slide-up"
      data-sal-duration="2000"
      data-sal-delay="300"
      data-sal-easing="ease"
    />
    <Products
      prefix="ssr: true"
      data-sal="slide-up"
      data-sal-duration="2000"
      data-sal-delay="300"
      data-sal-easing="ease"
    />
    <Testimonials
      data-sal="slide-up"
      data-sal-duration="2000"
      data-sal-delay="300"
      data-sal-easing="ease"
    />
    <BlogSection
      prefix="ssr: true"
      data-sal="slide-up"
      data-sal-duration="2000"
      data-sal-delay="300"
      data-sal-easing="ease"
    />
  </Layout>
);

export default IndexPage;
