import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import './hero.style.css';

const HeroSection = () => {
  const data = useStaticQuery(graphql`
  {
    contentfulHeroSection {
      subHeading
      heading
      highlight
      socialLinks {
        name
        link
      }
      button
    }
  }
`);

  const heroSectionData = data.contentfulHeroSection;

  return (
    <header className="header items-center bg-primary flex flex-wrap h-screen/7 md:h-screen/8 lg:h-screen relative w-full m-h-min transition-all duration-2 ease-in-out after:w-0 after:h-0 after:absolute after:left-140vw after:bottom-20px after:content after:z-2 after:border-t-20px after:border-r-120vw after:border-b-20px after:border-l-120vw after:border-t-transparent after:border-r-bold after:border-b-transparent after:border-l-transparent">
      <div className="container xl:px-[3.3rem] xl:mx-auto md:mx-auto px-4 relative z-3">
        <div className="w-full text-secondary">
          <small className="font-futura text-[0.75rem] md:text-[1rem]  text-secondary mb-md w-full tracking-1 translate-y-50 delay-1">
            Using software to help your business adopt to
            <b className="ml-2 text-bold font-normal pb-8 border-b-secondary border-b-solid border-b-1">what is next</b>
            .
          </small>
          <h1 className="font-futura text-secondary text-[1.4rem] ssm:text-[2rem] md:text-[3.5rem] lg[4rem]  font-bold delay-75 translate-y-50 tracking-wide leading-1.3">
            {heroSectionData.heading}
          </h1>
          <a className="uppercase cta no-underline bg-bold shadow-lg text-primary inline-block text-md font-bold h-4 leading-lg mt-5 px-wide translate-y-50 mb-5 rounded-lg transition-all ease-in-out delay-100 hover:opacity-95" rel="noreferrer" target="_blank" href="https://vitamin2.typeform.com/to/zNyk8z">{heroSectionData.button}</a>
        </div>
      </div>
    </header>
  );
};

export default HeroSection;
